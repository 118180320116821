import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../component/Header";
import Footer from "../../../component/Footer";
import SidebarFilter from "../../../component/sidebarFilter/SidebarFilter";
import { categories } from "../ElectricianServiceData";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { addToCart } from "../../../Redux/Cartdata/CartDataAction";
const Electrician = () => {
  const goToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const cartData = useSelector((state) => state.addCart.cartDataArray);
  const dispatch = useDispatch();
  const addToCartHandler = (product, categories, item) => {
    const updatedCart = {
      category: categories[0].title,
      subCategory: item.name,
      serviceId: product.id,
      serviceName: product.name,
      serviceImage: product.image,
      servicePrice: product.price,
      serviceQuantity:1,
    };
    const newCartData = [...cartData];
    newCartData.push(updatedCart);
    dispatch(addToCart(newCartData));
    swal({
      title: "Successfully Added",
      text: `${product.id} is added to cart.`,
      icon: "success",
      button: "Ok",
    });
  };
  return (
    <>
      <Header />
      <div className="flex">
        <SidebarFilter goToSection={goToSection} />
        <div className="container mx-auto p-8">
          <h1 className="text-4xl font-bold mb-4">{categories[0].title}</h1>
          {categories[0].subCategory.map((item) => {
            return (
              <>
                <h1 className="text-3xl font-bold m-8" id={item.name}>
                  {item.name}
                </h1>
                <div
                  className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4"
                  style={{
                    boxShadow: "0 8px 26px 0 rgba(0,0,0,0.08)",
                    marginBottom: "5rem",
                  }}
                >
                  {item.service.map((product) => (
                    <div
                      key={product.id}
                      className="bg-white p-4 hover:shadow-lg rounded-lg"
                    >
                      <img
                        src={product.image}
                        alt={product.name}
                        className="w-full h-48 object-contain mb-4"
                      />
                      <h3 className="text-lg font-semibold mb-2">
                        {product.name}
                      </h3>
                      <p className="text-gray-500 mb-4">
                        Price: ${product.price}
                      </p>
                      <button
                        className="bg-indigo-500 text-white px-4 py-2 rounded-full hover:bg-indigo-600"
                        onClick={() => {
                          addToCartHandler(product, categories, item);
                        }}
                      >
                        Book
                      </button>
                    </div>
                  ))}
                </div>
              </>
            );
          })}
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Electrician;
