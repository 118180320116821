import plumber from "../../assets/plumber.jpg";
import electrician from "../../assets/electrician.jpg";
import carpenter from "../../assets/carpenter.jpg";
import carwashing from "../../assets/carwashing.jpg";
import homeCleaning from "../../assets/homeCleaning.jpg";
import kitchenCleaning from "../../assets/kitchenCleaning.jpg";
import pestControl from "../../assets/pestControl.jpg";
import menMassage from "../../assets/menMassage.jpg";
import menSalon from "../../assets/menSalon.jpg";
import womenSalon from "../../assets/womenSalon.jpg";
import Hair1 from "../../assets/Hair1.jpg";

export const categories = [
    {
      message: "Salon, Spa and Massage services",
      para: "Hygienic & Single use products | Low-contact services",
      services: [
        { image: Hair1, imagePara: "Spa for Women" },
        { image: menMassage, imagePara: "Home massage for Men" },
        { image: womenSalon, imagePara: "Home Salon for Women" },
        { image: menSalon, imagePara: "Home Salon for Men" },
      ],
    },
    {
      message: "Cleaning & Pest Control ",
      para: "Hygienic & Single use products | Low-contact services",
      services: [
        { image: carwashing, imagePara: "Car washing" },
        { image: kitchenCleaning, imagePara: "Kitchen and Bathroom Cleaning" },
        { image: homeCleaning, imagePara: "Full Home Cleaning" },
        { image: pestControl, imagePara: "Pest Control" },
      ],
    },
    {
      message: "Home Repairs",
      para: "Top Professionals | Best Brands | Premium Experience",
      services: [
        { image: plumber, imagePara: "Plumbers" },
        { image: electrician, imagePara: "Electricians" },
        { image: carpenter, imagePara: "Carpenters" },
      ],
    },
  ];