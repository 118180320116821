import React from "react";
import { useNavigate, Link } from "react-router-dom";

const CardBox = ({ children, title }) => {
 
  return (
    <div
      className=" md:m-8 text-center md-9 dark:bg-gray-800 dark:text-gray-100 w-full shadow-md border-gray-300 shadow:0 10px 5px #ccc"
      style={{
        boxShadow: "0 8px 26px 0 rgba(0,0,0,0.08)",
        marginBottom: "5rem",
      }}
    >
      <h2 className="text-3xl font-bold m-5">{title}</h2>
      <div className="flex flex-wrap justify-center">{children}</div>
    </div>
  );
};

export const BeautifulCard = ({ photo, heading, para , href}) => {
  return (
    <div className="m-5 w-62 text-center card glass bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:scale-105 cursor-pointer">
      <Link to = {href}>
      <figure className="h-40">
        <img
          src={photo}
          alt="car"
          className="object-cover w-full h-full rounded-t-lg transition-all duration-300"
        />
      </figure>
      <div className="card-body p-4">
        <h2 className="text-3xl font-bold mb-2">{heading}</h2>
        <p className="text-gray-600">{para}</p>
      </div>
      </Link>
    </div>
  );
};
export default CardBox;
