import { CART_DATA, USER_DATA, WORKER_DATA } from "./BookingType";

export const cartData = (data)=>{
    return{
        type:CART_DATA,
        payload:data,
    }
}

export const workerData = (data)=>{
    return{
        type:WORKER_DATA,
        payload:data,
    }
}

export const userData = (data)=>{
    return{
        type:USER_DATA,
        payload:data,
    }
}