import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addTotal, removeTotal } from "../Redux/Cartdata/CartDataAction";

const CartService = ({ service, removeService, id }) => {
  const [quantity, setQuantity] = useState(service.serviceQuantity);
  const dispatch = useDispatch();

  return (
    <div className="flex items-center hover:bg-gray-100 -mx-8 px-6 py-5">
      <div className="flex w-2/5">
        <div className="w-20">
          <img className="h-24" src={service.serviceImage} alt="" />
        </div>
        <div className="flex flex-col justify-between ml-4 flex-grow">
          <span className="font-bold text-sm">{service.serviceName}</span>
          <span className="text-red-500 text-xs">{service.subCategory}</span>
          <span className="text-red-500 text-xs">{service.category}</span>
          <a
            href="#"
            className="font-semibold hover:text-red-500 text-gray-500 text-xs"
            onClick={() => removeService(id, service.servicePrice)}
          >
            Remove
          </a>
        </div>
      </div>
      <div className="flex justify-center w-1/5">
        <p
          className="text-3xl hover:cursor-pointer"
          onClick={() => {
            setQuantity(quantity - 1);
            dispatch(removeTotal(service.servicePrice));
          }}
          hidden={quantity == 0 ? true : false}
        >
          -
        </p>

        <input
          className="mx-2 border text-center w-8"
          type="text"
          value={quantity}
        />
        <p
          className="text-3xl hover:cursor-pointer"
          onClick={() => {
            setQuantity(quantity + 1);
            dispatch(addTotal(service.servicePrice));
          }}
        >
          +
        </p>
      </div>
      <span className="text-center w-1/5 font-semibold text-sm">
        ${service.servicePrice}
      </span>
      <span className="text-center w-1/5 font-semibold text-sm">
        ${service.servicePrice * quantity}
      </span>
    </div>
  );
};

export default CartService;
