import React from "react";
import classnames from "classnames";

const Service = ({ items }) => {

  const imageClass = classnames('base-class', {
   'sm:grid-cols-2': items.services.length === 4? true:false,
   'sm:grid-cols-3': items.services.length === 3? true:false,
   'lg:grid-cols-3': items.services.length === 3? true:false,
   'lg:grid-cols-4': items.services.length === 4? true:false,
  });
  return (
    <section
      className=" md:m-8  md-9 dark:bg-gray-800 dark:text-gray-100 w-full shadow-md border-gray-300 shadow:0 10px 5px #ccc"
      style={{ boxShadow: "0 8px 26px 0 rgba(0,0,0,0.08)", marginBottom: "5rem"}}
    >
      <div className="container mx-auto p-4 my-4 space-y-2 text-center">
        <h2 className="text-3xl font-bold mb-5">{items.message}</h2>
        <p className="space-y-1 leading-snug mt-5">{items.para}</p>
      </div>
      <div className={`container mx-auto grid justify-center gap-4 ${imageClass}`}>
        {items.services.map((item) => {
          return (
            <div className="flex flex-col items-center p-4 ">
              <img
                src={item.image}
                alt="Service Logo"
                className="h-40 rounded-md hover:scale-105 transition-transform duration-300 cursor-pointer"
                style={{ width: "17rem" }}
              />
              <div className="space-y-1 leading-snug mt-5 font-semibold">
                <p>{item.imagePara}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Service;
