import { useState } from "react";
import Header from "../../component/Header";
import "./style.css";
import Service from "../../component/Service";
import { categories } from "./homeServices";
import Footer from "../../component/Footer";
import bgImage2 from "../../assets/bgImage3.jpg"
import SearchBar from "../../component/SearchBar";
export default function Home() {
 
  return (
    <>
      <div
        className="relative bg-cover bg-center bg-no-repeat h-70 w-auto opacity-150 bg-white bg-opacity-70"
        style={{
          backgroundImage: `url(${bgImage2})`,
        }}
      >
        <div
          style={{
            width:"100%",
            height:"auto",
            background:
              "linear-gradient(180deg, rgba(0,0,0,0.16), rgba(0,0,0,0.8))",
          }}
        >
          <Header />
          {/* background image start */}
          <div className="relative isolate px-6 pt-14 lg:px-8">
            <div
              className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80y"
              aria-hidden="true"
            ></div>
          </div>
          <SearchBar />
        </div>
      </div>
      {/* background image end */}
      {categories.map((items) => {
        return (
          <>
            <div className="flex justify-center">
              <Service items={items} />
            </div>
            <div class=" w-auto mb-11 border-t-4 border-gray-200">
            </div>
          </>
        );
      })}
       
      <Footer />
    </>
  );
}
