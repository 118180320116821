import React from 'react';
import Header from '../../component/Header';
import CardBox from '../../component/CardBoxCard'
import {BeautifulCard} from '../../component/CardBoxCard'
import { exploreCards } from './cardsData';
import Footer from "../../component/Footer";


const Explore = () => {
  return (
    <>
      <Header />
      <div className="container mx-auto p-4 my-4 space-y-2 text-center">
      <h1 class="mt-10 text-center text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-5xl dark:text-white">Discover Local  <span class="text-blue-600 dark:text-blue-500">Experts and Services</span> Near You </h1>
      <p className="space-y-1 text-center text-2xl leading-snug mt-5">Explore all services by category.</p>
      </div>
      {exploreCards.map(item => {
        return (
          <CardBox title = {item.title}>
          {item.cards.map((card) => {
            return <BeautifulCard photo = {card.photo} heading = {card.heading} para = {card.para} href={card.href}/>
          })}
        </CardBox>
        )
      })}
    <Footer />
    </>
  );
};

export default Explore;
