import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../../component/Header";

const SerbersAroundYou = () => {
  const location = useLocation();
  const { prop1 } = location.state;
  return <>
  <Header />
  </>;
};

export default SerbersAroundYou;
