import React from "react";
import Typewritter from "../component/TypeWriter";
const SearchBar = () => {
  return (
    <>
      <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-32">
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            <Typewritter />
          </h1>
          <div className=" items-center">
            <input
              type="text"
              placeholder="Search for any services"
              className="py-2 px-4 rounded-full my-10 bg-white focus:outline-none focus:ring-2 w-64 focus:ring-indigo-500 items-center"
            />
            <button
              type="button"
              className="ml-2 px-4 py-2 rounded-full bg-indigo-500 text-white font-semibold hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Search
            </button>
          </div>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="#"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Get started
            </a>
            <a href="#" className="text-sm font-semibold leading-6 text-white">
              Learn more <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBar;
