import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Home from './Pages/home/Home';
import About from "./Pages/About"
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Explore from './Pages/explore/Explore';
import Electrician from './Pages/BookByCategory/electrician/Electrician';
import Login from "./Pages/LoginPage/Login"
import SerbersAroundYou from './Pages/serbersAroundYou/SerbersAroundYou';
import { Provider } from 'react-redux';
import store from './Redux/store';
import Cart from './Pages/Cart/Cart';
import Freelancer from './Pages/freelancer/Freelancer';
import BookWorker from './component/BookWorker';
import Booking from './Pages/Booking/Booking';
import BookingDetail from './component/BookingDetail';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<Provider store = {store}>
 <BrowserRouter>
 <Routes>
  <Route path='/' Component={Home} />
  <Route path='/about' Component={About} />
  <Route path='/explore' Component={Explore} />
  <Route path='/electrician' Component={Electrician} />
  <Route path='/login' Component={Login} />
  <Route path='/serbers' Component={SerbersAroundYou} />
  <Route path='/cart' Component={Cart} />
  <Route path='/freelancer' Component={Freelancer} />
  <Route path='/book' Component={BookWorker} />
  <Route path='/booking' Component={Booking} />
  <Route path='/bookingDetail' Component={BookingDetail} />
 </Routes>
 </BrowserRouter>
 </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
