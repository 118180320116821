import React from "react";
import Header from "../component/Header";

const About = () => {
  return (
    <div className="relative bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]">
    <div className="absolute inset-x-0 top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
      <div
        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
      />
    </div>
    <header className="py-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-white text-center">About Us</h1>
      </div>
    </header>
    <main className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="bg-white rounded-lg shadow-lg p-8">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">Our Story</h2>
            <p className="text-gray-700">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed commodo, libero id varius hendrerit, erat
              massa facilisis lacus, vitae euismod orci nunc eget est. Etiam nec fermentum leo. Suspendisse aliquet
              dui id sapien lobortis, eu tempor felis sodales. Mauris non mauris at sem facilisis fermentum vitae
              eu libero.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-8">
            <h2 className="text-3xl font-bold text-gray-800 mb-4">Our Mission</h2>
            <p className="text-gray-700">
              Vestibulum ac consequat nunc. Duis auctor dignissim ipsum, id scelerisque ligula mattis et. Morbi
              pulvinar diam vitae est lobortis, in pretium leo scelerisque. Suspendisse potenti. Cras euismod mi
              metus, ac facilisis nisl consectetur sit amet. Suspendisse ut elit at ex finibus varius.
            </p>
          </div>
        </div>
      </div>
    </main>
    <footer className="py-6 bg-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <p className="text-gray-600 text-center">
          &copy; {new Date().getFullYear()} Your Company. All rights reserved.
        </p>
      </div>
    </footer>
  </div>
  );
};
export default About;
