import React,{useState} from "react";
import Header from "../../component/Header";
import { freelancerData } from "./freelancersData";
import FreelancerCard from "../../component/FreelancerCard";
import { FreelancerChildren } from "../../component/FreelancerCard";
import BookWorker from "../../component/BookWorker";

const Freelancer = () => {
    const [showModal, setShowModal] = useState(false); 
    const [worker, setWorker] = useState({});
    const handleModal = ()=>{
        setShowModal(!showModal);
    }
    const handleWorker = (data)=>{
        setWorker(data);
    }
  return <>
  <Header />
  <h1 class="mt-10 text-center text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-5xl dark:text-white">Workers <span class="text-blue-600 dark:text-blue-500">Around </span> You </h1>
  <p className="space-y-1 text-center text-2xl leading-snug mt-5">Choose your own worker.</p>
  {freelancerData.map(item => {
        return (
          <FreelancerCard title = {item.title}>
          {item.users.map((user) => {
            return <FreelancerChildren photo = {user.photo} name = {user.name} area={user.AreaCovered} handleModal = {handleModal} handleWorker = {handleWorker} work = {user.work}/>
          })}
        </FreelancerCard>
        )
      })}
      {showModal ? (
        <>
        <BookWorker handleModal={handleModal} workerDetail = {worker}/>
        </>
      ) : null};
  </>;
};

export default Freelancer;
