import { ADD_TO_CART, ADD_TOTAL, REMOVE_TOTAL } from "./CartDataType";

const initialState = {
  cartDataArray: [],
  total: 0,
};

const addCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        cartDataArray: action.payload,
      };
    case ADD_TOTAL:
      return {
        ...state,
        total: state.total + action.payload,
      };
    case REMOVE_TOTAL:
      return {
        ...state,
        total: state.total - action.payload,
      };
    default:
      return state;
  }
};

export default addCartReducer;
