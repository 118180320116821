import photo from "../../assets/cleaning1.jpg";
export const categories = [{
    title:"Electricians",
    subCategory:[
      {
        name:"Fan",
        service:[
          {
            id: 1,
            name: 'Product 1',
            image: photo,
            price: 1999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
        ]
      },
      {
        name:"Decorative Light",
        service:[
          {
            id: 1,
            name: 'Product 1',
            image: photo,
            price: 1999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
        ]
      },
      {
        name:"Switch and Socket",
        service:[
          {
            id: 1,
            name: 'Product 1',
            image: photo,
            price: 1999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
        ]
      },
      {
        name:"Light",
        service:[
          {
            id: 1,
            name: 'Product 1',
            image: photo,
            price: 1999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
        ]
      },
      {
        name:"MCB and Fuse",
        service:[
          {
            id: 1,
            name: 'Product 1',
            image: photo,
            price: 1999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
        ]
      },
      {
        name:"Inverter and Stabilizer",
        service:[
          {
            id: 1,
            name: 'Product 1',
            image: photo,
            price: 1999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
        ]
      },
      {
        name:"Appliance",
        service:[
          {
            id: 1,
            name: 'Product 1',
            image: photo,
            price: 1999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
        ]
      },
      {
        name:"Wiring",
        service:[
          {
            id: 1,
            name: 'Product 1',
            image: photo,
            price: 1999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
        ]
      },
      {
        name:"Door Bell",
        service:[
          {
            id: 1,
            name: 'Product 1',
            image: photo,
            price: 1999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
          {
            id: 2,
            name: 'Product 2',
            image: photo,
            price: 2999,
          },
        ]
      },

    ]
}]