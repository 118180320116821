import React from "react";
import "./SidebarFilter.css";
import Light from "../../assets/electrician/Light.png";
import Doorbell from "../../assets/electrician/Doorbell.png";
import appliance from "../../assets/electrician/appliance.png";
import decorative from "../../assets/electrician/decorative.png";
import fan from "../../assets/electrician/fan.png";
import inverter from "../../assets/electrician/inverter.png";
import mcb from "../../assets/electrician/mcb.png";
import socket from "../../assets/electrician/socket.png";
import wiring from "../../assets/electrician/wiring.png";

const SidebarFilter = ({goToSection}) => {
  const bookingData = [
    {
      image: fan,
      itemName: "Fan",
    },
    {
      image: decorative,
      itemName: "Decorative Light",
    },
    {
      image: socket,
      itemName: "Switch and Socket",
    },
    {
      image: Light,
      itemName: "Light",
    },
    {
      image: mcb,
      itemName: "MCB and Fuse",
    },
    {
      image: inverter,
      itemName: "Inverter and Stabilizer",
    },
    {
      image: appliance,
      itemName: "Appliance",
    },
    {
      image: wiring,
      itemName: "Wiring",
    },
    {
      image: Doorbell,
      itemName: "Door Bell",
    },
  ];

  return (
    <div
      className="sidebar-container p-4 m-4 dark:bg-gray-800 dark:text-gray-10 shadow-md border-gray-300 shadow:0 10px 5px #ccc"
      style={{
        boxShadow: "0 8px 26px 0 rgba(0,0,0,0.08)",
        width: "25%",
        height:"100%",
        // overflowY: "scroll",
      }}
    >
      <div className="sidebar">
        <div className="filter-group">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {bookingData.map((item) => {
              return (
                <>
                  <div
                    style={{
                      padding: "10px",
                      border: "1px solid white",
                      width: "80px",
                      textAlign: "center",
                      borderRadius: "20%",
                      backgroundColor: "#f5f5f5",
                      cursor:"pointer",
                    }}
                    onClick={()=>{goToSection(item.itemName)}}
                  >
                    <img
                      src={item.image}
                      alt="Image"
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </div>
                  <p style={{ marginTop: "5px", marginBottom: "15px" }}>
                    {item.itemName}
                  </p>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarFilter;
