import electrician from "../../assets/electrician.jpg";
import carpenter from "../../assets/carpenter.jpg";
import plumber from "../../assets/plumber.jpg";
import painting from "../../assets/kitchenCleaning.jpg";
import repair from "../../assets/bgImage3.jpg";

export const exploreCards = [
  {
    title: "Home Service",
    cards: [
      {
        photo: electrician,
        heading: "Electricians",
        para: "Get all appliances repairing",
        href:"/electrician"
      },
      {
        photo: plumber,
        heading: "Plumbers",
        para: "Get all appliances repairing",
      },
      {
        photo: carpenter,
        heading: "Carpenters",
        para: "Get all appliances repairing",
      },
      {
        photo: painting,
        heading: "Painters",
        para: "Get all appliances repairing",
      },
      {
        photo: repair,
        heading: "Appliances Repair",
        para: "Get all appliances repairing",
      },
    ],
  },
  {
    title: "Beauty and Wellness",
    cards: [
      {
        photo: electrician,
        heading: "Salon at Home",
        para: "Get all appliances repairing",
      },
      {
        photo: plumber,
        heading: "Spa at Home",
        para: "Get all appliances repairing",
      },
      {
        photo: carpenter,
        heading: "Party Makeup Artists",
        para: "Get all appliances repairing",
      },
      {
        photo: painting,
        heading: "Bridal Makeup Artists",
        para: "Get all appliances repairing",
      },
      {
        photo: repair,
        heading: "Yoga Instructors",
        para: "Get all appliances repairing",
      },
    ],
  },
  {
    title: "Repairs and Maintenance",
    cards: [
      {
        photo: electrician,
        heading: "Mobile Repair",
        para: "Get all appliances repairing",
      },
      {
        photo: plumber,
        heading: "Laptop Repair",
        para: "Get all appliances repairing",
      },
      {
        photo: carpenter,
        heading: "Geyser Repair",
        para: "Get all appliances repairing",
      },
      {
        photo: painting,
        heading: "Washing Machine Repair",
        para: "Get all appliances repairing",
      },
      {
        photo: repair,
        heading: "Refrigerator Repair",
        para: "Get all appliances repairing",
      },
      {
        photo: repair,
        heading: "TV Repair",
        para: "Get all appliances repairing",
      },
    ],
  },
  {
    title: "Car and Bike Services",
    cards: [
      {
        photo: electrician,
        heading: "Car Cleaning and Detailing",
        para: "Get all appliances repairing",
      },
      {
        photo: plumber,
        heading: "Car Mechanics",
        para: "Get all appliances repairing",
      },
      {
        photo: carpenter,
        heading: "Bike Service and Repair",
        para: "Get all appliances repairing",
      },
    ],
  },
];
