import photo from "../../assets/Freelancer/fr1.jpg";
export const freelancerData = [
  {
    title: "Electricians",
    users: [
      {
        name: "Rakesh gautam",
        age: 48,
        photo: photo,
        AreaCovered: "Shrinathpuram to KeshavPura",
        work:"Electrician"
      },
      {
        name: "Prabhat Verma",
        age: 37,
        photo: photo,
        AreaCovered: "KeshavPura to Talwandi",
        work:"Electrician"
      },
      {
        name: "Paramjeet singh",
        age: 40,
        photo: photo,
        AreaCovered: "Talwandi to Commerce college Road",
        work:"Electrician"
      },
      {
        name: "Dhawan Meena",
        age: 32,
        photo: photo,
        AreaCovered: "Vigyan nagar to Aerodram circle",
        work:"Electrician"
      },
    ],
  },
];
