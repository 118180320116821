import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../component/Header";
import BookingDetail from "../../component/BookingDetail";
import { workerData } from "../../Redux/Bookings/BookingAction";
import swal from "sweetalert";
const Booking = () => {
  const bookingdata = useSelector((state) => state.booking);
  const [showModal, setShowModal] = useState(false); 
  const dispatch = useDispatch();
  const handleShowModal = ()=>{
    setShowModal(!showModal)
  }
  const cancelRequest = (id)=>{
    const newWorker = [...bookingdata.worker];
    newWorker.splice(id, 1);
    dispatch(workerData(newWorker));
    swal({
      title: "Request Cancelled",
      text: "Your request is cancelled",
      icon: "error",
      button: "Ok",
    });
  }
  return (
    <>
      <Header />
      <div className="mb-16">
        <dh-component>
          <div className="container flex justify-center mx-auto pt-10">
            <div>

              <h1 className="xl:text-4xl text-3xl text-center text-gray-800 font-extrabold pb-6 sm:w-4/6 w-full mx-auto">
                Pending Invitations                                           
              </h1>
            </div>
          </div>
          {bookingdata.worker.length > 0 ? <div className="w-full bg-gray-100 px-10 pt-10">
            <div className="container mx-auto">
              <div
                role="list"
                aria-label="Behind the scenes People "
                className="lg:flex md:flex sm:flex items-center xl:justify-between flex-wrap md:justify-around sm:justify-around lg:justify-around"
              >
                   {bookingdata.worker.map((worker, id) => {
                        return( <div
                            role="listitem"
                            className="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5"
                          >
                            <div className="rounded overflow-hidden shadow-md bg-white">
                              <div className="absolute -mt-20 w-full flex justify-center">
                                <div className="h-32 w-32">
                                  <img
                                    src={worker.photo}
                                    alt="Display Picture of Andres Berlin"
                                    role="img"
                                    className="rounded-full object-cover h-full w-full shadow-md"
                                  />
                                </div>
                              </div>
                           
                              <div className="px-6 mt-16">
                                <h1 className="font-bold text-3xl text-center mb-1">
                                  {worker.name}
                                </h1>
                                <p className="text-gray-800 text-sm text-center font-semibold">
                                  {worker.area}
                                </p>
                                <p className="text-gray-800 text-sm text-center font-semibold">
                                  {worker.work}
                                </p>
                            
                                <div className="w-full flex justify-center pt-5 pb-5">
                                 <p className="text-gray-800 mr-5 mt-2 font-bold text-xl text-center">Status</p>
                                 <button  className="text-white hover:cursor-default font-semibold inline-flex items-center  bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 rounded-lg text-sm px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800">Pending</button>
                                </div>
                                <div className="w-full flex justify-between">
                                <button className="text-white mb-5 font-semibold bg-indigo-500 hover:bg-indigo-800  focus:ring-4 focus:outline-none focus:ring-indigo-300 rounded-lg text-sm px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800" onClick={handleShowModal} >Get Detail</button>
                                <button className="text-white mb-5 font-semibold bg-red-500 hover:bg-red-800  focus:ring-4 focus:outline-none focus:ring-indigo-300 rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800" onClick={()=> cancelRequest(id)} >Cancel request</button>
                                </div>
                              </div>
                            </div>
                          </div>)
                    })}
               
              </div>
            </div>
          </div>:<div>
            <h1 className="xl:text-2xl  mt-28 text-3xl text-center text-gray-800 font-extrabold pb-6 sm:w-4/6 w-full mx-auto">No Invitations send!</h1>
            </div>}
          
        </dh-component>
      </div>
      {showModal ? (
        <BookingDetail handleShowModal = {handleShowModal}/>
      ):null}
    </>
  );
};

export default Booking;
