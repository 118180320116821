import { ADD_TO_CART } from "./CartDataType";
import { ADD_TOTAL, REMOVE_TOTAL } from "./CartDataType";

export const addToCart = (data)=>{
    return {
        type:ADD_TO_CART,
        payload:data
    }
}

export const addTotal = (data)=>{
    return{
        type:ADD_TOTAL,
        payload:data
    }
}

export const removeTotal = (data)=>{
    return {
        type:REMOVE_TOTAL,
        payload:data,
    }
}