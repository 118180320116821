import React, { useEffect, useState } from "react";
import axios from "axios";
import logo from "../../assets/Logo.png"
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import Header from "../../component/Header";

const Login = () => {
  const [usersData, setUsersData] = useState({
    name:"",
    email:"",
    password:"",
  });
  const navigate = useNavigate();
  const fetchUsers = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "http://localhost:3000/users",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        const userdata = response.data;
        // setUsersData(userdata);
        console.log(typeof userdata);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const postData = ()=>{
    let data = JSON.stringify(usersData);
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'http://localhost:3000/users',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const submitData = (e)=>{
    e.preventDefault();
    postData();
    console.log(usersData);
    swal({
        title: "Good job!",
        text: "Successfully logedin",
        icon: "success",
        button: "Ok",
      });
    navigate('/');
  }
  useEffect(() => {
    fetchUsers();
    // postData();
  }, []);
  return (
   <>
   <Header />
   <section className="bg-gray-50 dark:bg-gray-900">
  <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
    <a
      href="#"
      className="flex items-center text-2xl font-semibold text-gray-900 dark:text-white"
    >
      <img
        className="mr-2"
        style={{width:"180px", height:"180px"}}
        src={logo}
        alt="logo"
      />
    </a>
    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
          Login to your account
        </h1>
        <form className="space-y-4 md:space-y-6" action="#" onSubmit={(e) => submitData(e)}>
          <div>
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="name"
              value={usersData.name}
              onChange={(e) => {setUsersData({...usersData, name:e.target.value})}}
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="name@company.com"
              required=""
              value={usersData.email}
              onChange={(e) => {setUsersData({...usersData, email:e.target.value})}}
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="••••••••"
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required=""
              value={usersData.password}
              onChange={(e) => {setUsersData({...usersData, password:e.target.value})}}
            />
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="remember"
                  aria-describedby="remember"
                  type="checkbox"
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-indigo-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-indigo-600 dark:ring-offset-gray-800"
                  required=""
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="remember"
                  className="text-gray-500 dark:text-gray-300"
                >
                  Remember me
                </label>
              </div>
            </div>
            <a
              href="#"
              className="text-sm font-medium text-indigo-600 hover:underline dark:text-indigo-500"
            >
              Forgot password?
            </a>
          </div>
          <button
            type="submit"
            className="w-full text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-indigo-600 dark:indigo:bg-indigo-700 dark:focus:ring-indigo-800"
          >
            Login
          </button>
          <p className="text-sm font-light text-gray-500 dark:text-gray-400">
            Don’t have an account yet?{" "}
            <a
              href="#"
              className="font-medium text-indigo-600 hover:underline dark:text-indigo-500"
            >
              Sign up
            </a>
          </p>
        </form>
      </div>
    </div>
  </div>
</section>

   </>
  );
};

export default Login;
