import React from "react";

//importing typewriter-effect
import Typewriter from "typewriter-effect";

function Typewritter() {
  return (
    <div>
      <Typewriter
        options={{
          strings: ["Home Services", "On Demand"],
          autoStart: true,
          loop: true,
        }}
      />
    </div>
  );
}

export default Typewritter;
