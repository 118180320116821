import { CART_DATA, WORKER_DATA, USER_DATA } from "./BookingType";

const initialState = {
    cart:[],
    worker:[],
    user:{},
}

const bookingReducer = (state=initialState, action)=>{
    switch(action.type){
        case CART_DATA: return {
            ...state,
            cart:action.payload,
        };
        case WORKER_DATA: return{
            ...state,
            worker:action.payload,
        };
        case USER_DATA: return{
            ...state,
            user:action.payload,
        };

        default: return state;
    }
}

export default bookingReducer