import React from "react";
import { useSelector } from "react-redux";

const BookingDetail = ({ handleShowModal }) => {
  const bookingData = useSelector((state) => state.booking);
  console.log(bookingData);

  return (
    <>
      <div className="justify-center items-center flex  fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
          {/* Modal content */}
          <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
            {/* Modal header */}
            <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
              <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                Booking Detail
              </h1>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="defaultModal"
                onClick={handleShowModal}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="relative overflow-x-auto">
              <h1 className="text-lg font-semibold text-gray-900 dark:text-white mb-5 text-center">
                Service Detail
              </h1>
              <div className="max-h-60 overflow-y-auto">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Service Image
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Service name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Category
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Sub Category
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookingData.cart.map((service) => {
                      return (
                        <tr
                          key={service.id}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                        >
                          <td>
                            <img src={service.serviceImage} alt="" />
                          </td>
                          <td
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {service.serviceName}
                          </td>
                          <td className="px-6 py-4">{service.category}</td>
                          <td className="px-6 py-4">{service.subCategory}</td>
                          <td className="px-6 py-4">${service.servicePrice}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="relative overflow-x-auto mt-10">
              <h1 class="text-lg font-semibold text-gray-900 dark:text-white mb-5 text-center">
                User Detail
              </h1>
              <div className="flex mt-10 mb-5">
                <h3 className="font-semibold text-gray-600 text-xs uppercase w-2/4">
                  Address
                </h3>
                <h3 className="font-semibold  text-gray-600 text-xs uppercase w-1/4 text-center">
                  Time slot
                </h3>
                <h3 className="font-semibold  text-gray-600 text-xs uppercase w-1/4 text-center">
                  Status
                </h3>
              </div>
              <div className="flex items-center hover:bg-gray-100 -mx-8 px-6">
                <div className="flex w-2/4">
                  <div className="flex flex-col justify-between ml-2 flex-grow">
                    <span className="font-bold text-sm">
                      {bookingData.user.address}
                    </span>
                    <a
                      href="#"
                      className="font-semibold mt-2 hover:text-indigo-500 text-gray-500 text-xs"
                    >
                      Change Address
                    </a>
                  </div>
                </div>
                <span className="text-center w-1/4 font-semibold text-sm">
                  {bookingData.user.timeSlot}
                </span>
                <span className="text-center w-1/4 font-semibold text-sm">
                  Pending
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default BookingDetail;
