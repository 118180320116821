import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaGoogle, FaYoutube, FaPinterest, FaLinkedin } from 'react-icons/fa';
import logo from "../assets/Logo.png"
import { hover } from '@testing-library/user-event/dist/hover';

const Footer = () => {
  return (
    <footer className="bg-gray-800 flex flex-col md:flex-row items-center justify-between">
    <div className="container mx-auto flex items-center">
      <img src={logo} alt="Logo" className="hover:brightness-125 transition-all hover:cursor-pointer" style={{ height: "auto", width: "8rem" }} />
    </div>
    <div className="container mx-auto flex justify-center">
      <a href="#" className="text-white hover:text-gray-300 text-xl mx-2">
        <FaFacebook />
      </a>
      <a href="#" className="text-white hover:text-gray-300 text-xl mx-2">
        <FaTwitter />
      </a>
      <a href="#" className="text-white hover:text-gray-300 text-xl mx-2">
        <FaInstagram />
      </a>
      <a href="#" className="text-white hover:text-gray-300 text-xl mx-2">
        <FaGoogle />
      </a>
      <a href="#" className="text-white hover:text-gray-300 text-xl mx-2">
        <FaYoutube />
      </a>
      <a href="#" className="text-white hover:text-gray-300 text-xl mx-2">
        <FaPinterest />
      </a>
      <a href="#" className="text-white hover:text-gray-300 text-xl mx-2">
        <FaLinkedin />
      </a>
      <a href="#" className="text-white hover:text-gray-300 text-xl mx-2">
        <FaYoutube />
      </a>
    </div>
    <div className="container mx-auto mt-4 text-right">
      <p className="text-gray-400 text-sm">&copy; 2023 Serbing. All rights reserved.</p>
    </div>
  </footer>
  

  )
}

export default Footer